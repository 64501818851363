import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import formatDate from '../../../../helpers/date_formatter'
import routes from '../../../../../routes'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import Text from '../../../../elements/text/text'
import TextLink from '../../../../elements/text_link/text_link'
import Image from '../../../../elements/image/image'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './invited_signature.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const InvitedSignature = ({
	image,
	name,
	twitter,
	web,
	shortBio,
	instagram,
	facebook,
	title,
	company,
	publishDate,
	category,
	className
}) => {
	let socialMediaUserName
	let socialMediaLink
	if (twitter) {
		socialMediaUserName = `@${twitter}`
		socialMediaLink = routes.twitter(twitter)
	} else if (facebook) {
		socialMediaUserName = `@${facebook}`
		socialMediaLink = routes.facebook(facebook)
	} else if (instagram) {
		socialMediaUserName = `@${instagram}`
		socialMediaLink = routes.instagram(instagram)
	}

	return (
		<footer className={classnames(className, styles.ContainerInvitedAuthor)}>
			<div className={styles.TimeAndCategory}>
				<Text as="span" size="14">
					<time dateTime={publishDate}>{formatDate(publishDate)}</time>
				</Text>
				<div className={styles.FiledContainer}>
					<Text as="span" size="14">
						Filed under
					</Text>
					<TextLink to={routes.category({ slug: category.slug })} size="16" chubby>
						{category.title}
					</TextLink>
				</div>
			</div>

			<div className={styles.InvitedAuthor}>
				<div className={styles.Image}>
					<Image fluid={image.fluid} wrapperClassName={styles.ImageInner} />
				</div>
				<div className={styles.Description}>
					<Text size="18" chunky>
						{name}
					</Text>
					<Text size="16" className={styles.Title}>
						{title}
						{!!company && ` at ${company}`}
					</Text>
					<div
						dangerouslySetInnerHTML={{
							__html: shortBio.childMarkdownRemark.html
						}}
					/>
					<Text size="16" chubby className={styles.Links}>
						{socialMediaUserName && (
							<TextLink external chubby to={socialMediaLink}>
								{socialMediaUserName}
							</TextLink>
						)}
						{web && (
							<React.Fragment>
								{!!socialMediaUserName && ' | '}
								<TextLink external chubby to={web}>
									{web}
								</TextLink>
							</React.Fragment>
						)}
					</Text>
				</div>
			</div>
		</footer>
	)
}

InvitedSignature.propTypes = {
	image: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	twitter: PropTypes.string,
	web: PropTypes.string,
	shortBio: PropTypes.string,
	instagram: PropTypes.string,
	facebook: PropTypes.string,
	title: PropTypes.string,
	company: PropTypes.string,
	publishDate: PropTypes.string.isRequired,
	category: PropTypes.object.isRequired,
	className: PropTypes.string
}

InvitedSignature.defaultProps = {
	twitter: '',
	web: '',
	shortBio: '',
	instagram: '',
	facebook: '',
	title: '',
	company: '',
	className: ''
}

/* ====================================================== */
/*                      Public API                        */
/* ====================================================== */

export default InvitedSignature
