import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import routes from '../../../../../routes'
import formatDate from '../../../../helpers/date_formatter'

/* ====================================================== */
/*                   Actions / Selectors                  */
/* ====================================================== */

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import Text from '../../../../elements/text/text'
import TextLink from '../../../../elements/text_link/text_link'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './edpuzzle_signature.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const EdpuzzleSignature = ({ publishDate, category, className }) => {
	return (
		<footer className={classnames(className, styles.Container)}>
			<Text as="span" size="14">
				<time dateTime={publishDate}>{formatDate(publishDate)}</time> -{' '}
				<div className={styles.WrittenBy}>
					{' '}
					Written by
					<TextLink to={routes.edpuzzle()} rel="external" external size="14" trackAnalytics chubby>
						{' '}
						Edpuzzle
					</TextLink>{' '}
					staff
				</div>
			</Text>

			<div className={styles.Category}>
				<Text as="span" size="14">
					Filed under{' '}
				</Text>
				<TextLink chubby to={routes.category({ slug: category.slug })} size="14">
					{category.title}
				</TextLink>
			</div>
		</footer>
	)
}

EdpuzzleSignature.propTypes = {
	publishDate: PropTypes.string.isRequired,
	category: PropTypes.object.isRequired,
	className: PropTypes.string
}

EdpuzzleSignature.defaultProps = {
	className: ''
}

/* ====================================================== */
/*                      Public API                        */
/* ====================================================== */

export default EdpuzzleSignature
