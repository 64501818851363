import React from 'react'
import PropTypes from 'prop-types'

const FacebookIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="11"
		height="20"
		viewBox="0 0 11 20"
		aria-labelledby="facebook-icon"
		role="img"
		{...props}
	>
		<title id="facebook-icon">{props.alt}</title>
		<path
			fillRule="nonzero"
			d="M8.992 3.32H11V.14A28.6 28.6 0 0 0 8.075 0c-2.896 0-4.88 1.656-4.88 4.7v2.8H0v3.555h3.196V20h3.916v-8.945h3.067l.488-3.555H7.113V5.05c0-1.027.304-1.73 1.879-1.73z"
		/>
	</svg>
)

FacebookIcon.propTypes = {
	alt: PropTypes.string.isRequired
}

export default FacebookIcon
