import React from 'react'
import PropTypes from 'prop-types'

const TwitterIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="16"
		viewBox="0 0 20 16"
		aria-labelledby="twitter-icon"
		role="img"
		{...props}
	>
		<g>
			<title id="twitter-icon">{props.alt}</title>
			<path
				fillRule="nonzero"
				d="M17.944 3.987c.013.175.013.35.013.525C17.957 9.85 13.833 16 6.294 16c-2.322 0-4.48-.663-6.294-1.812.33.037.647.05.99.05 1.916 0 3.68-.638 5.089-1.725-1.802-.038-3.313-1.2-3.833-2.8.254.037.508.062.774.062.368 0 .736-.05 1.079-.137-1.878-.376-3.287-2-3.287-3.963v-.05c.546.3 1.18.488 1.853.512A4.02 4.02 0 0 1 .838 2.775c0-.75.203-1.438.558-2.038a11.71 11.71 0 0 0 8.452 4.225 4.492 4.492 0 0 1-.102-.924c0-2.226 1.828-4.038 4.1-4.038 1.18 0 2.245.487 2.994 1.275A8.145 8.145 0 0 0 19.442.3a4.038 4.038 0 0 1-1.802 2.225A8.316 8.316 0 0 0 20 1.9a8.74 8.74 0 0 1-2.056 2.087z"
			/>
		</g>
	</svg>
)

TwitterIcon.propTypes = {
	alt: PropTypes.string.isRequired
}

export default TwitterIcon
