import React from 'react'
import { graphql } from 'gatsby'
import routes from '../routes'
import formatDate from '../ui/helpers/date_formatter'

import { COLORS } from '../ui/identity/colors'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import Layout from '../ui/layout/layout'
import SEO from '../ui/components/seo/seo'
import ArticleSignature from '../ui/components/article_signature/article_signature'
import Image from '../ui/elements/image/image'
import Text from '../ui/elements/text/text'
import Button from '../ui/elements/button/button'
import TextLink from '../ui/elements/text_link/text_link'
import Newsletter from '../ui/components/newsletter_subscription/newsletter_subscription'
import ShareSocialMediaIcons from '../ui/components/social_media_share_icon/social_media_share_icon'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './post_page.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const Post = ({ data, location }) => {
	const {
		title,
		description,
		metaDescription,
		slug,
		minutesReading,
		publishDate,
		tags,
		body,
		callToAction,
		heroImage,
		author,
		category
	} = data.contentfulPost

	return (
		<Layout location={location}>
			<SEO
				title={title}
				description={metaDescription}
				keywords={tags}
				url={routes.post({ categorySlug: category.slug, postSlug: slug, base: true })}
				image={heroImage.file.url}
				imageAlt={heroImage.description}
				author={author.name}
			/>
			<article className={styles.ArticleContainer}>
				<div className={styles.ArticleInformation}>
					<header className={styles.ArticleHeader}>
						<TextLink
							as="link"
							size="18"
							tag
							color={category.color}
							to={routes.category({ slug: category.slug })}
						>
							{category.title.toUpperCase()}
						</TextLink>
						<Text as="h1" size="56" chunky color={COLORS.VULCAN} className={styles.ArticleTitle}>
							{title}
						</Text>
						<Text
							as="p"
							size="24"
							color={COLORS.GRAPHITE}
							role="doc-subtitle"
							className={styles.ArticleSubtitle}
						>
							{description}
						</Text>
					</header>
					<aside className={styles.ArticleInfoAsideWrapper}>
						<div className={styles.ArticleInfoAsideInfo}>
							<Text as="p" code size="12" color={COLORS.ASHES}>
								<time dateTime={publishDate}>{formatDate(publishDate).toUpperCase()}</time>
							</Text>
							<Text as="p" code size="12" color={COLORS.ASHES} className={styles.ArticleInfoSeparator}>
								-
							</Text>
							<Text as="p" code size="12" color={COLORS.ASHES}>
								{`${minutesReading}' read`.toUpperCase()}
							</Text>
						</div>
						<div className={styles.ArticleInfoAsideAuthor}>
							<Text as="p" code size="12" color={COLORS.ASHES}>
								BY {author.name.toUpperCase()}
							</Text>
						</div>
					</aside>
				</div>
				<div className={styles.GreyBackground} />
				<div className={styles.Notebook} />
				<div className={styles.ArticleHeroImageWrapper}>
					<Image
						alt={heroImage.description}
						fluid={heroImage.fluid}
						wrapperClassName={styles.ArticleHeroImage}
					/>
					<Text as="p" size="16" color={COLORS.ASHES}>
						Illustration by{' '}
						<TextLink
							as="link"
							size="16"
							chubby
							color={COLORS.GRAPHITE}
							external
							rel="external"
							trackAnalytics
							to={routes.edpuzzle()}
						>
							Edpuzzle
						</TextLink>{' '}
						Staff
					</Text>
				</div>
				<div className={styles.ArticleTextWrapper}>
					<aside className={styles.SocialMediaLinksWrapper}>
						<nav aria-label="Share on social media" className={styles.SocialMediaLinksNav}>
							<ShareSocialMediaIcons
								sharedURL={routes.post({
									postSlug: slug,
									categorySlug: category.slug,
									base: true
								})}
								text={title}
							/>
						</nav>
					</aside>
					<div>
						<div
							className={styles.ArticleBodyWrapper}
							dangerouslySetInnerHTML={{
								__html: body.childMarkdownRemark.html
							}}
						/>
						{callToAction && callToAction.url && callToAction.title ? (
							<div className={styles.ArticleCTA}>
								<Button as="link" external rel="noopener" trackAnalytics large to={callToAction.url}>
									{callToAction.title}
								</Button>
							</div>
						) : null}
					</div>
				</div>
				<ArticleSignature
					category={category}
					author={author}
					publishDate={publishDate}
					className={styles.ArticleSignature}
				/>
				{/* <section title={`Newsletter subscription`} className={styles.NewsletterSection}>
					<Newsletter compact />
				</section> */}
			</article>
		</Layout>
	)
}

export default Post

export const pageQuery = graphql`
	query ($slug: String!) {
		contentfulPost(slug: { eq: $slug }) {
			title
			description
			metaDescription
			slug
			minutesReading
			publishDate
			tags
			body {
				childMarkdownRemark {
					html
				}
			}
			callToAction {
				title
				url
			}
			heroImage {
				file {
					url
				}
				description
				fluid(maxWidth: 1536) {
					...GatsbyContentfulFluid
				}
			}
			author {
				name
				isInvitedAuthor
				title
				company
				image {
					description
					fluid(maxWidth: 100, quality: 100) {
						...GatsbyContentfulFluid
					}
				}
				shortBio {
					childMarkdownRemark {
						html
					}
				}
				twitter
				web
				facebook
				instagram
			}
			category {
				title
				slug
				color
			}
		}
	}
`
