import React from 'react'
import PropTypes from 'prop-types'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import TwitterIcon from '../../helpers/icons/twitter/twitter_icon'
import FacebookIcon from '../../helpers/icons/facebook/facebook_icon'
import LinkedinIcon from '../../helpers/icons/linkedin/linkedin_icon'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './social_media_share_icon.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

export const ShareTwitterIcon = ({ sharedURL, text }) => (
	<OutboundLink
		rel="noopener noreferrer"
		target="_blank"
		href={`https://twitter.com/intent/tweet?url=${sharedURL}&text=${text}&via=edpuzzle`}
		title="Share on Twitter"
		className={styles.ShareLink}
	>
		<TwitterIcon alt="Twitter icon" />
	</OutboundLink>
)

export const ShareFacebookIcon = ({ sharedURL }) => (
	<OutboundLink
		rel="noopener noreferrer"
		target="_blank"
		href={`https://www.facebook.com/sharer/sharer.php?u=${sharedURL}`}
		title="Share on Facebook"
		className={styles.ShareLink}
	>
		<FacebookIcon alt="Facebook icon" />
	</OutboundLink>
)

export const ShareLinkedInIcon = ({ sharedURL }) => (
	<OutboundLink
		rel="noopener noreferrer"
		target="_blank"
		href={`https://www.linkedin.com/sharing/share-offsite/?url=${sharedURL}`}
		title="Share on Linkedin"
		className={styles.ShareLink}
	>
		<LinkedinIcon alt="Linkedin icon" />
	</OutboundLink>
)

const ShareSocialMediaIcons = props => (
	<React.Fragment>
		<ShareTwitterIcon {...props} />
		<ShareFacebookIcon {...props} />
		<ShareLinkedInIcon {...props} />
	</React.Fragment>
)

ShareSocialMediaIcons.defaultProps = {
	text: 'Edpuzzle Blog'
}

ShareSocialMediaIcons.propTypes = {
	sharedURL: PropTypes.string.isRequired,
	text: PropTypes.string
}

export default ShareSocialMediaIcons
