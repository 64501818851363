import React from 'react'
import PropTypes from 'prop-types'

const LinkedinIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		aria-labelledby="linkedin-icon"
		role="img"
		{...props}
	>
		<title id="linkedin-icon">{props.alt}</title>
		<path
			fillRule="nonzero"
			d="M4.477 20H.33V6.647h4.147V20zM2.4 4.826C1.075 4.826 0 3.727 0 2.4a2.401 2.401 0 1 1 4.803 0c0 1.326-1.076 2.425-2.402 2.425zM19.996 20h-4.138v-6.5c0-1.55-.031-3.536-2.156-3.536-2.156 0-2.486 1.683-2.486 3.424V20H7.074V6.647h3.977v1.821h.058c.553-1.049 1.906-2.156 3.923-2.156C19.23 6.312 20 9.076 20 12.665V20h-.004z"
		/>
	</svg>
)

LinkedinIcon.propTypes = {
	alt: PropTypes.string.isRequired
}

export default LinkedinIcon
