import React from 'react'
import PropTypes from 'prop-types'

/* ====================================================== */
/*                     Components                         */
/* ====================================================== */

import InvitedSignature from './components/invited_signature/invited_signature'
import EdpuzzleSignature from './components/edpuzzle_signature/edpuzzle_signature'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const ArticleSignature = ({ className, author, publishDate, category }) => {
	const { isInvitedAuthor, ...authorInfo } = author

	if (isInvitedAuthor)
		return (
			<InvitedSignature {...authorInfo} publishDate={publishDate} category={category} className={className} />
		)
	return <EdpuzzleSignature publishDate={publishDate} category={category} className={className} />
}

ArticleSignature.propTypes = {
	className: PropTypes.string,
	author: PropTypes.object,
	publishDate: PropTypes.string.isRequired,
	category: PropTypes.shape({
		slug: PropTypes.string.isRequired
	})
}

ArticleSignature.defaultProps = {
	className: ''
}

export default ArticleSignature
